/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import { getCSRFToken } from '~/shared/utils/request';
import { InternalTracking } from '~/shared/modules/internal-tracking';
import { DrbToast } from '~/web-components/drb-toast/drb-toast-helpers';
import checkIcon from '~/assets/icons/v2-check-circle.svg?raw';

// Large user card used primarily in Designer Search
// `app/components/user/user_cards/resume_user_card_component.erb`
export const ResumeUserCard = {
  init() {
    this.bindEvents();
  },

  bindEvents() {
    document.addEventListener('click', (e) => {
      const bookmarkBtn = e.target.closest('[data-resume-user-card] [data-bookmark]');
      const showMoreBtn = e.target.closest('[data-extra-skills-btn]');
      if (bookmarkBtn) this.bookmarkUser(bookmarkBtn);
      if (showMoreBtn) this.toggleExtraSkills(e);
      this.handleProfileRedirect(e);
    });
  },

  handleProfileRedirect(e) {
    const profilePath = e.target.closest('[data-profile-path]')?.getAttribute('data-profile-path');
    const userID = e.target.closest('[data-resume-user-card][data-id]')?.getAttribute('data-id');
    const isClickable = !!e.target.closest('a, input, button, label, drb-tooltip');
    if (!profilePath || !userID || isClickable) return;

    InternalTracking.trackSearchProfileClicked(userID, e.target);
    window.open(profilePath, '_blank').focus();
  },

  toggleExtraSkills(e) {
    const container = e.target.closest('[data-resume-user-card-footer]');
    const skills = container?.querySelectorAll('.pill2');
    const button = e.target.closest('[data-extra-skills-btn]');
    const extraSkillsTextCount = button?.querySelector('[data-extra-skills-text-count]');
    const extraSkillsTextHide = button?.querySelector('[data-skills-text-hide]');

    skills.forEach((skill) => {
      skill.classList.toggle('display-flex');
    });

    extraSkillsTextCount?.classList.toggle('display-none');
    extraSkillsTextHide?.classList.toggle('display-none');
  },

  async bookmarkUser(bookmarkBtn) {
    // if not logged in, trigger upsell modal and return early
    const isLoggedIn = window.Dribbble?.JsConfig?.user?.isLoggedIn || false;
    if (!isLoggedIn) return this.openDesignerSearchUpsellModal();

    const resumeCard = bookmarkBtn.closest('[data-resume-user-card]');
    const username = resumeCard?.getAttribute('data-username');
    const displayName = resumeCard?.getAttribute('data-display-name');
    const savedSearchId = resumeCard?.getAttribute('data-saved-search-id');
    if (!username) return;

    const isCreatingBookmark = !bookmarkBtn.hasAttribute('data-bookmarked');

    const DELETE_PATH = (savedSearchId
      ? `/client_app/talent/saved_searches/${savedSearchId}/bookmarks/${username}`
      : `/client_app/talent/bookmarks/${username}`);

    const CREATE_PATH = '/client_app/talent/bookmarks';

    const requestOptions = {
      path: isCreatingBookmark ? CREATE_PATH : DELETE_PATH,
      method: isCreatingBookmark ? 'POST' : 'DELETE',
      body: JSON.stringify({
        userId: username,
      }),
    };

    try {
      // show loading state
      bookmarkBtn.classList.add('btn2--loading');

      // make request
      const { path, method, body } = requestOptions;

      const response = await fetch(path, {
        method,
        body,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      if (!response.ok) throw new Error('Something went wrong');

      // update UI
      bookmarkBtn.toggleAttribute('data-bookmarked', isCreatingBookmark);
      bookmarkBtn.classList.toggle('btn2--icon-expand-animation', isCreatingBookmark);

      if (isCreatingBookmark) {
        DrbToast.open({
          title: 'Designer Saved',
          messageHtml: `<span>${displayName || username} was saved to your <a href="/designers?tab=bookmarks">Saved Designers</a></span>`,
          iconHtml: checkIcon,
          variant: 'success',
          duration: 5000,
        });

        InternalTracking.trackSearchDesignerBookmarked(resumeCard.getAttribute('data-id'), resumeCard);
      }
    } catch (error) {
      Dribbble.EventBus.$emit('siteNotifications:add', {
        title: 'Error updating bookmark',
        id: 'update-bookmark',
      });
    } finally {
      bookmarkBtn.classList.remove('btn2--loading');
    }
  },

  openDesignerSearchUpsellModal() {
    const modal = document.querySelector('drb-dialog#designer-search-upsell-modal');
    if (modal) modal.open();
  },
};
